// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('datatables.net')
require('datatables.net-bs4')
import moment from 'moment'
window.moment = moment

//option 1
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
//option 2
import 'bootstrap';
require("stylesheets/main")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// https://altalogy.com/blog/rails-6-user-accounts-with-3-types-of-roles/
// global.toastr = require("toastr")

import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';


// require("chartkick")
require("chart.js");
import 'chartkick/dist/chartkick.js';
// import 'chart.js/dist/chart.js';
// import 'chart.js/dist/chart.css';

$(document).keypress(
  function(event){
    if (event.which == '13') {
      event.preventDefault();
    }
});
